import {
  Document,
  Font,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

import { EXPORT_TEMPLATE } from "../..";
import { REPORT_YEN_BAI } from "./Common/reportConfig";
import { styles } from "./Common/style";

import BienBanBanGiaoMau from "./reports/bienBanBanGiaoMau";
import BienBanLayMau from "./reports/bienBanLayMau";
import PhieuPhanTich from "./reports/phieuPhanTich";
import PhieuYeuCauKiemNghiem from "./reports/phieuYeuCauKiemNghiem";

import Font_Bold_Italic from "src/assets/fonts/font-times-new-roman-bold-italic.ttf";
import Font_Bold from "src/assets/fonts/font-times-new-roman-bold.ttf";
import Font_Italic from "src/assets/fonts/font-times-new-roman-italic.ttf";
import Font_Time_New_Roman from "src/assets/fonts/font-times-new-roman.ttf";
import BienBanBanGiaoMauPhongKeHoach from "./reports/bienBanBanGiaoMauPhongKeHoach";
import PhieuKiemNghiem from "./reports/phieuKiemNghiem";
import SoNhapMauGui from "./reports/soNhapMauGui";
import SoNhapMauLay from "./reports/soNhapMauLay";

Font.register({
  family: "TimeNewRomanSSS",
  fonts: [
    {
      src: Font_Time_New_Roman,
    },
    {
      src: Font_Bold,
      fontWeight: "bold",
    },
    {
      src: Font_Italic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: Font_Bold_Italic,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

// Register font
Font.register({
  family: "time",
  src: Font_Time_New_Roman,
});
Font.register({
  family: "time-bold",
  src: Font_Bold,
});
Font.register({
  family: "time-italic",
  src: Font_Italic,
});

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

export default function PdfReport(props) {
  const objectProps = toReportProps(props);

  switch (objectProps.type) {
    case EXPORT_TEMPLATE.bienBanLayMau:
      objectProps.configObject = REPORT_YEN_BAI.bienBanLayMau;
      return <BienBanLayMau {...objectProps} />;

    case EXPORT_TEMPLATE.bienBanBanGiaoMau:
      objectProps.configObject = REPORT_YEN_BAI.bienBanBanGiaoMau;
      return <BienBanBanGiaoMau {...objectProps} />;

    case EXPORT_TEMPLATE.bienBanBanGiaoMauPhongKeHoach:
      objectProps.configObject = REPORT_YEN_BAI.bienBanBanGiaoMauPhongKeHoach;
      return <BienBanBanGiaoMauPhongKeHoach {...objectProps} />;

    case EXPORT_TEMPLATE.phieuYeuCauKiemNghiem:
      //ko có table
      return <PhieuYeuCauKiemNghiem {...objectProps} />;

    case EXPORT_TEMPLATE.phieuPhanTich:
      objectProps.configObject = REPORT_YEN_BAI.phieuPhanTich;
      return <PhieuPhanTich {...objectProps} />;

    case EXPORT_TEMPLATE.phieuKiemNghiem:
      objectProps.configObject = REPORT_YEN_BAI.phieuKiemNghiem;
      return <PhieuKiemNghiem {...objectProps} />;

    case EXPORT_TEMPLATE.soNhapMauLay:
      return <SoNhapMauLay {...objectProps} />;

    case EXPORT_TEMPLATE.soNhapMauGui:
      return <SoNhapMauGui {...objectProps} />;

    default:
      return <DefaultReport />;
  }
}

function DefaultReport() {
  return (
    <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
      <Document>
        <Page size="A4" style={styles.page} orientation="portrait">
          <View style={styles.section}>
            <Text>Vui lòng chọn loại biên bản</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

function toReportProps(props) {
  const currentSubStanceType =
    props.data[0]?.substanceInformation[0]?.substanceType;
  const currentDate = props.data[0]?.samplingDate
    ? new Date(props.data[0]?.samplingDate)
    : new Date();

  const info = props.data[0] || {};
  const getInfo = (key) => info[key] || "";

  return {
    ...props,
    currentDate,
    currentSubStanceType,
    info,
    getInfo,
  };
}
