import { Image, Text } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../style";
import Underline from "./underline";

export function TitleLeft(props) {
  return (
    <>
      {!props.isNotLogo ? (
        <Image
          style={[styles.logo, { marginTop: "-40px" }]}
          src={process.env.PUBLIC_URL + "images/yenbai-icon.png"}
        />
      ) : (
        <></>
      )}

      <Text>SỞ Y TẾ TỈNH YÊN BÁI</Text>
      <Text style={styles.bold}>
        TRUNG TÂM KIỂM NGHIỆM{"\n"}
        THUỐC, MỸ PHẨM, THỰC PHẨM
      </Text>
      <Underline width="60%" />
    </>
  );
}

export function TitleLeftCustomForPPT() {
  return (
    <>
      <Text>SỞ Y TẾ TỈNH YÊN BÁI</Text>
      <Text style={styles.bold}>
        TRUNG TÂM KIỂM NGHIỆM{"\n"}
        THUỐC - MỸ PHẨM - THỰC PHẨM
      </Text>
      <Text>589 Đ.Yên Ninh - TP.Yên Bái - Tỉnh Yên Bái</Text>
      <Text>Điện thoại - Fax: 0216 817 789</Text>
      <Underline width="60%" />
    </>
  );
}

export function TitleRight() {
  return (
    <>
      <Text style={styles.bold}>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</Text>
      <Text style={styles.bold}>Độc lập - Tự do - Hạnh phúc</Text>
      <Underline width="25%" />
    </>
  );
}
