import React, { useEffect, useMemo, useRef, useState } from "react";
import Datatable from "src/components/Datatable";
import { base64StringToBlob } from "blob-util";
import SampleForm from "./form";
import { useTranslation } from "react-i18next";
import { Select, Input, message, Modal, Row, Col, Tag } from "antd";
import { getListTemplate } from "../../apis/Template";
import ModalShowErr from "./components/ModalShowError";
import { apiCheckReport } from "../../apis/samples";
import {
  getListSample,
  apiDeleteSample,
  apiDissociateStorage,
  uploadTemplate,
  updateStatusSample,
} from "../../apis/samples";
import {
  handleActionByRole,
  handleDownloadFile,
  timeFormatter,
} from "../../utils/utils";
import { CONFIG } from "../../configs";
import UploadTemplate from "../../components/UploadTemplate";
import { PDFViewer } from "@react-pdf/renderer";
import CloneRecord from "./components/CloneRecord";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/permission/selector";
import {
  Sample_Add,
  Sample_Cancel,
  Sample_Delete,
  Sample_Edit,
  Sample_Export,
  Sample_Import,
  Sample_MoveToCart,
  Sample_Receive,
  Sample_Restore,
  Sample_SendExternal,
  Sample_Split,
} from "../../constants/roles.constant";
import SampleFormDerivative from "./formDerivative";
import FormCard from "./formCard";
import BarcodePdfView from "../../components/SamplePdf/BarcodePdfView";
import { useHistory } from "react-router";
import ModalAnalyte from "./components/ModalAnalyte";
import FileUtil from "../Document/Utils/FileUtil";
import { getFileById } from "../Document/Services/document.services";
import { notification } from "antd";
import i18next from "i18next";
import PdfReport from "./components/Reports";
import useQueryParams from "../../hooks/useQueryParams";

const allowDownload = ["barcode"];

const renderColumns = (configs, t, base64StringToBlob) => {
  if (!configs?.configData) return [];
  const columns = [];

  let barcodeItem = null;
  const index = configs.configData.config.findIndex(
    (x) => x?.sectionName === "generalInformation"
  );
  if (index > 0)
    [configs.configData.config[0], configs.configData.config[index]] = [
      configs.configData.config[index],
      configs.configData.config[0],
    ];
  configs.configData.config.map((item) => {
    Object.keys(item.attribute).map((key) => {
      if (
        [
          "sampleId",
          "custodianId",
          "custodianPosition",
          "idCard",
          "status",
          "importFacility",
          "importFacilityAddress",
          "packedSize",
          "representativePerson",
          "position",
          "priority",
          "planName",
          "issuedDate",
          "issuedDatePlan",
          "fromDate",
          "introductoryPaper",
          "attachedFile",
          "importDrug",
          "documentName",
        ].includes(key)
      )
        return;
      else if (item.attribute[key].type.toUpperCase() === "UPLOAD") {
        const fixed = key === "barcode" ? "right" : false;
        const col = {
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
          fixed: fixed,
          render: (value) => {
            if (item.sectionName.toUpperCase() === "SAMPLEIMAGE") {
              const toggleClass = "hover-zoom";
              return (
                <div
                  style={{ width: 50, height: 50 }}
                  onMouseLeave={(e) => {
                    let imgEle = e.currentTarget.getElementsByTagName("img")[0];
                    imgEle && imgEle.classList.toggle(toggleClass);
                  }}
                  onMouseEnter={(e) => {
                    let imgEle = e.currentTarget.getElementsByTagName("img")[0];
                    if (imgEle) {
                      const rect = imgEle.getBoundingClientRect();
                      imgEle.style.left = rect.left + "px";
                      //zoom height = 300px, class = toggleClass
                      imgEle.style.top = rect.top - 150 + "px";
                      imgEle.classList.toggle(toggleClass);
                    }
                  }}
                  onClick={() => {
                    if (allowDownload.includes(key)) {
                      handleDownloadFile(CONFIG.API_URL.BASE + value);
                    }
                  }}
                >
                  <img
                    src={`${CONFIG.API_URL.BASE + value}`}
                    alt=""
                    style={{ maxHeight: 35, display: "inline-block" }}
                  />
                </div>
              );
            }

            return (
              <span
                className={`tw-inline-block ${
                  allowDownload.includes(key) ? "tw-cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (allowDownload.includes(key)) {
                    handleDownloadFile(CONFIG.API_URL.BASE + value);
                  }
                }}
              >
                <img
                  src={`${CONFIG.API_URL.BASE + value}`}
                  alt=""
                  style={{ maxHeight: 35, display: "inline-block" }}
                />
              </span>
            );
          },
        };
        if (key === "barcode") {
          return (barcodeItem = col);
        }
        return columns.push(col);
      } else if (key === "substanceName") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceName`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceName)
                xhtml.push(
                  <div key={index}>
                    {x.substanceName}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "substanceContent") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceContent`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceContent)
                xhtml.push(
                  <div key={index}>
                    {x.substanceContent}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "substanceType") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceType`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceType)
                xhtml.push(
                  <div key={index}>
                    {x.substanceType}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "substanceUnit") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceUnit`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceUnit)
                xhtml.push(
                  <div key={index}>
                    {x.substanceUnit}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "drugNameRelatedDocs") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `drugNameRelatedDocs`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.drugNameRelatedDocs)
                xhtml.push(
                  <div key={index}>
                    {x.drugNameRelatedDocs}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (item.attribute[key].type.toUpperCase() === "DATE") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
          render: (value) => {
            return (
              <div>
                {Number.isInteger(value) ? timeFormatter(value) : value}
              </div>
            );
          },
        });
      } else if (item.attribute[key].type.toUpperCase() === "DROPDOWN") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
          render: (value) => {
            if (key === "priority") {
              let color = "default";
              switch (value) {
                case "mức thấp":
                  color = "success";
                  break;
                case "mức trung bình":
                  color = "warning";
                  break;
                case "mức cao":
                  color = "error";
                  break;
                default:
              }
              return <Tag color={color}>{value}</Tag>;
            } else {
              return <div>{value}</div>;
            }
          },
        });
      } else if (key === "samplerName") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerName`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerName)
                xhtml.push(
                  <div key={index}>
                    {x.samplerName}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "samplerId") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerId`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerId)
                xhtml.push(
                  <div key={index}>
                    {x.samplerId}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "samplerPosition") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerPosition`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerPosition)
                xhtml.push(
                  <div key={index}>
                    {x.samplerPosition}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else if (key === "samplerIdCard") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerIdCard`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerIdCard)
                xhtml.push(
                  <div key={index}>
                    {x.samplerIdCard}
                    <br />
                  </div>
                );
            });
            return <div>{xhtml}</div>;
          },
        });
      } else {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
        });
      }
      return key;
    });
    return item;
  });
  // if (barcodeItem) {
  //   columns.push(barcodeItem);
  // }
  // columns.push({
  //   title: t(`SAMPLE.parentName`),
  //   width: 150,
  //   dataIndex: `parentName`,
  //   fixed: "right",
  // });
  columns.push({
    title: t(`SAMPLE.FORM.documentName`),
    width: 200,
    dataIndex: `documentId`,
    render: (_, record) => {
      let xhtml = [];
      record.samplerInformation.forEach((x, index) => {
        if (x.samplerId)
          xhtml.push(
            <div key={index}>
              {x.samplerId}
              <br />
            </div>
          );
      });
      return (
        <a
          onClick={async () => {
            try {
              const file = await getFileById(_);
              window.open(file.data.data.fileUrl, "_blank");
              return;
              const blob = base64StringToBlob(
                file.data.data.base64,
                file.data.data.type
              );
              FileUtil.previewFile(blob, "application/pdf");
            } catch (err) {
              notification["warning"]({
                message: "",
                description: i18next.t(
                  "DOCUMENT.MODAL.REVIEW.ApiNotify.errorType"
                ),
              });
              // NotifyUtil.showApiError();
            }
          }}
        >
          {"Link"}
        </a>
      );
    },
  });
  columns.push({
    title: t("SAMPLE.status"),
    width: 150,
    dataIndex: "sampleStatus",
    fixed: "right",
    render: function (text) {
      switch (text) {
        case "NOT_RECEIVE":
          return t("STATUSTEMPLATESAMPLE.NOT_RECEIVE");
        case "RECEIVED":
          return t("STATUSTEMPLATESAMPLE.RECEIVED");
        case "STORED":
          return t("STATUSTEMPLATESAMPLE.STORED");
        case "EXPORTED":
          return t("STATUSTEMPLATESAMPLE.EXPORTED");
        case "DESTROYED":
          return t("STATUSTEMPLATESAMPLE.DESTROYED");
        case "ANALYZING":
          return t("STATUSTEMPLATESAMPLE.ANALYZING");
        case "MOVE_TO_CART":
          return t("STATUSTEMPLATESAMPLE.MOVE_TO_CART");
        case "REPORT_SAMPLE_HAS_EXISTED":
          return t("STATUSTEMPLATESAMPLE.REPORT_SAMPLE_HAS_EXISTED");
        case "REPORTED":
          return t("STATUSTEMPLATESAMPLE.REPORTED");

        default:
          return text;
      }
    },
  });
  return columns;
};

const Samples = () => {
  const { t } = useTranslation();
  const modalRef = useRef();
  const modalRefSample = useRef();
  const modalRefCard = useRef();
  const modalAnayle = useRef();
  const tableRef = useRef();
  const cloneRef = useRef();

  const history = useHistory();
  const roles = useSelector(selectRole);

  const [specialDataSource, setSpecialDataSource] = useState([]);
  const [numberReports, setNumberReports] = useState(null);
  const [dataForms, setDataForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false);
  const [isError, setisError] = useState(false);
  const [listErr, setlistErr] = useState([]);
  const [visiblePdf, setVisiblePdf] = useState(false);
  const [visiblePdfBarcode, setVisiblePdfBarcode] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [typeReport, setTypeReport] = useState();
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  const [queryParams] = useQueryParams();
  const [query, setQuery] = useState({
    page: 1,
    size: queryParams?.itemsPerPage || 10,
    search: "",
  });
  const [total, setTotal] = useState(0);

  const columns = useMemo(() => {
    return renderColumns(form, t, base64StringToBlob);
  }, [form]);

  const fetchSamples = async (currentTemplate, selectedYear) => {
    setLoading(true);
    getListSample(
      currentTemplate,
      selectedYear,
      query.page,
      query.size,
      query.search
    )
      .then((result) => {
        const data = result.data?.sampleList.map((item) => {
          let record = {};
          Object.keys(item).map((key) => {
            if (
              key === "samplerInformation" ||
              key === "substanceInformation" ||
              key === "targetInformation"
            ) {
              return (record[key] = item[key]);
            } else {
              return (record = {
                ...record,
                ...item[key],
              });
            }
          });
          record["representativePerson"] =
            item?.["pickingInformation"]?.["representativePerson"];
          return record;
        });

        setDataTable(data);
        setTotal(result.data?.total || 0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleDelete = (ids) => {
    // let f = false;
    // dataSelected.forEach(d => {
    //   if (d?.sampleStatus === "REPORTED" || d?.sampleStatus === "MOVE_TO_CART") {
    //     f = true;
    //   }
    // })

    // if (f) {
    //   return message.warning("Trạng thái hiện tại không thể xóa!")

    // }
    const promises = [];
    ids.map((id) => {
      return promises.push(apiDeleteSample(id));
    });
    Promise.all(promises)
      .then(() => {
        fetchSamples(currentTemplate, selectedYear)
          .then((data) => {})
          .catch((err) => {});
      })
      .catch(() => {
        fetchSamples(currentTemplate, selectedYear).then();
      });
  };
  const reset = () => {
    fetchSamples(currentTemplate, selectedYear).then();
  };

  const onDissociateStorage = async (ids, type) => {
    if (type == "DESTROYED") {
      const res = await updateStatusSample({
        samplesIds: ids,
        status: type,
      });
      if (res.status === 200) message.success("Hủy mẫu thành công");
      else return message.warning(t("SAMPLE.MESSAGE." + res.data.code));
      fetchSamples(currentTemplate, selectedYear).then();
      return;
    }
    const promises = [];
    ids.map(async (id) => {
      var res = await apiDissociateStorage(id, type);
      if (res.status === 200) promises.push(res);
      else return message.warning(t("SAMPLE.MESSAGE." + res.data.code));
    });
    Promise.all(promises)
      .then(() => {
        fetchSamples(currentTemplate, selectedYear).then();
      })
      .catch(() => {
        fetchSamples(currentTemplate, selectedYear).then();
      });
  };

  const openForm = (record) => {
    if (currentTemplate == "0") {
      message.warning("Vui lòng chọn tên nhóm mẫu!");
      return;
    }
    // if (record && (record.sampleStatus === "EXPORTED" || record.sampleStatus === "REPORTED")) {
    //   message.warning("Trạng thái hiện tại không thể sửa!")
    //   return
    // }
    if (modalRefSample.current) {
      modalRefSample.current.open(form, record);
    }
  };

  const openModalCard = () => {
    if (modalRefCard.current) {
      modalRefCard.current.open(dataSelected);
    }
  };

  const openModalAnalyte = () => {
    if (modalAnayle.current) {
      modalAnayle.current.open(dataSelected);
    }
  };

  const openFormDerivative = (record, isPool = false) => {
    if (record.length === 0) record = dataSelected;
    if (modalRef.current) {
      modalRef.current.openDerivative(record, isPool);
    }
  };

  const clearSelectRows = () => {
    if (tableRef.current) {
      tableRef.current.clearSelectRows();
    }
  };

  const handleAfterSubmit = () => {
    clearSelectRows();
    fetchSamples(currentTemplate, selectedYear).then();
  };

  const onExport = (mode) => {
    if (currentTemplate == "0") {
      message.warning("Vui lòng chọn tên nhóm mẫu!");
      return;
    }

    if (mode === "all") {
      setLoading(true);
      getListSample(currentTemplate, selectedYear, 1, 2147483647)
        .then((result) => {
          const data = result.data?.sampleList.map((item) => {
            let record = {};
            Object.keys(item).map((key) => {
              if (
                key === "samplerInformation" ||
                key === "substanceInformation" ||
                key === "targetInformation"
              ) {
                return (record[key] = item[key]);
              } else {
                return (record = {
                  ...record,
                  ...item[key],
                });
              }
            });
            record["representativePerson"] =
              item?.["pickingInformation"]?.["representativePerson"];
            return record;
          });

          setSpecialDataSource(data);
          setVisiblePdf(true);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (!dataSelected || dataSelected.length <= 0) {
      return message.warning(t("SAMPLE.MESSAGE.warning_select_first"));
    } else {
      const userId = localStorage.getItem("IdUser");
      let filter = {
        sampleIds: dataSelected.map((item) => item.sampleId),
        technicianId: userId,
      };
      apiCheckReport(filter).then((result) => {
        setNumberReports(result.status === 200 ? result.data.codes : "");
        setVisiblePdf(true);
        reset();
      });
    }
  };

  const onShowBarcode = () => {
    if (!dataSelected || dataSelected.length <= 0) {
      return message.warning(t("SAMPLE.MESSAGE.warning_select_first"));
    }
    if (dataSelected.length === 1) setVisiblePdfBarcode(true);
  };

  useEffect(() => {
    if (currentTemplate) {
      fetchSamples(currentTemplate, selectedYear);
      setDataSelected([]);
    }
    // eslint-disable-next-line
  }, [currentTemplate, selectedYear, query]);

  const fetchListTemplate = async () => {
    const res = await getListTemplate("SAMPLE");
    if (res.status === 200) {
      let d = res.data || [];
      d.unshift({ id: "0", templateName: "Tất cả" });
      setDataForms(d);
      // setDataForms(dataMock);
      setForm(d[d.length - 1]);
      setCurrentTemplate(res.data[0]?.id || "");
    }
  };

  useEffect(() => {
    fetchListTemplate().then();
    // eslint-disable-next-line
  }, [module]);

  const changeForm = (id) => {
    if (id == "0") {
      setForm(dataForms[dataForms.length - 1]);
      setCurrentTemplate(id);
      return;
    }
    let val = dataForms.find((item) => item.id === id);
    setForm(val);
    setCurrentTemplate(val.id);
    setQuery((q) => {
      return { ...q, page: 1 };
    });
    tableRef.current.resetPagination();
  };

  const onUploadTemplate = () => {
    const val = !isOpenModalUpload;
    setIsOpenModalUpload(val);
  };

  const uploadFile = (file) => {
    uploadTemplate(file, currentTemplate)
      .then((result) => {
        setIsOpenModalUpload(false);
        if (result.status === 201) {
          message.success(t("ACTIONS.upload_success"));
          fetchSamples(currentTemplate, selectedYear);
        } else {
          let err = JSON.parse(result.data.details);
          setlistErr(err);
          setisError(true);
          // message.error(t("ACTIONS.upload_fail"));
        }
      })
      .catch(() => {});
  };

  const closeModalError = () => {
    setisError(false);
  };

  const toolbarExtra = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 20 }}>
          <label style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
            Tên nhóm mẫu
          </label>
        </div>
        <div style={{ marginRight: 20 }}>
          <Select
            showSearch
            style={{ minWidth: 100, marginRight: 20 }}
            placeholder="Select a template"
            optionFilterProp="children"
            onChange={(value) => changeForm(value)}
            value={currentTemplate || null}
            // value={module}
            className="custom-ant-select"
          >
            {dataForms
              ? dataForms.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.templateName}
                  </Select.Option>
                ))
              : ""}
          </Select>
        </div>
        <div style={{ marginRight: 20 }}>
          <label style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
            Năm
          </label>
        </div>
        <div style={{ marginRight: 20 }}>
          <Select
            showSearch
            style={{ minWidth: 100, marginRight: 20 }}
            placeholder="Chọn năm..."
            optionFilterProp="children"
            onChange={(v) => {
              tableRef.current.resetPagination();
              setQuery((q) => {
                return { ...q, page: 1 };
              });
              setSelectedYear(v);
            }}
            value={selectedYear}
            className="custom-ant-select"
          >
            {[...Array(currentYear - 2020 + 1).keys()].reverse().map((key) => {
              const value = key + 2020;
              return <Select.Option value={value}>{value}</Select.Option>;
            })}
          </Select>
        </div>
        <Input.Search
          allowClear
          // style={{ width: 200 }}
          width={100}
          placeholder={t("ACTIONS.search")}
          // onChange={searchTable}
          enterButton
          onSearch={(v) => {
            tableRef.current.resetPagination();
            setQuery((q) => {
              return { ...q, search: v, page: 1 };
            });
          }}
          // value={inputSearch}
        />
        {/*{roles.includes(Sample_Export) && (
          <Button type="primary" size={"default"} onClick={onExport}>
            <ExportOutlined /> {t("ACTIONS.export")}
          </Button>
        )}*/}
      </div>
    );
  };

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <Datatable
        tableRef={tableRef}
        loading={loading}
        columns={columns}
        dataSource={dataTable}
        rowKey={"sampleId"}
        autoFlatData={true}
        allowSelect={true}
        onDelete={handleDelete}
        onDissociateStorage={onDissociateStorage}
        onAddNew={openForm}
        onEdit={(record) => openForm(record)}
        toolbarExtra={toolbarExtra()}
        onUploadTemplate={onUploadTemplate}
        onSelect={(records) => setDataSelected(records)}
        onCloneRecord={(recordId) => cloneRef.current.open(recordId)}
        onOpenDerivatice={openFormDerivative}
        onOpenMoveToCard={openModalCard}
        onShowBarcode={onShowBarcode}
        openAnalyte={openModalAnalyte}
        onExport={onExport}
        updateQuery={(value) =>
          setQuery((q) => {
            return { ...q, page: value.current, size: value.pageSize };
          })
        }
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              history.push(`/sample/${record.sampleId}`);
            }, // double click row
          };
        }}
        total={total}
        actionConfig={handleActionByRole(
          {
            allowEdit: Sample_Edit,
            allowAddNew: Sample_Add,
            allowDelete: Sample_Delete,
            allowCancel: Sample_Cancel,
            allowRestore: Sample_Restore,
            allowReceive: Sample_Receive,
            allowMoveToCard: Sample_MoveToCart,
            allowSendOutSamples: Sample_SendExternal,
            allowImport: Sample_Import,
            allowAssign: true,
            allowUpload: true,
            allow1: false,
            allow2: false,
            allow3: false,
            allow4: false,
            allow5: false,
            allowCloneRecord: Sample_Split,
            allowMergeRecord: true,
            allow8: true,
            allow9: true,
            allow10: false,
            allow11: false,
            allow12: roles.includes(Sample_Export),
            allow13: true,
            allow14: false,
            allow15: false,
            allow16: true,
          },
          roles
        )}
      />

      <Modal
        title={t("MODAL.export")}
        visible={visiblePdf}
        centered
        width={"100%"}
        bodyStyle={{ height: "90vh" }}
        footer={null}
        onCancel={() => {
          setSpecialDataSource([]);
          setVisiblePdf(false);
          setTypeReport("");
        }}
      >
        <Row>
          <Col span={4}>{t("SAMPLE.reportType")}</Col>
          <Col
            span={4}
            className={"tw-flex tw-flex-row"}
            style={{ whiteSpace: "pre" }}
          >
            <Select
              value={typeReport}
              style={{ width: 120, marginBottom: "1rem" }}
              onChange={(value) => {
                setTypeReport(value);
              }}
            >
              {/* Phần chung của 2 mẫu */}
              <Select.Option value={EXPORT_TEMPLATE.bienBanLayMau}>
                {EXPORT_TEMPLATE.bienBanLayMau}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.bienBanBanGiaoMau}>
                {EXPORT_TEMPLATE.bienBanBanGiaoMau}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.bienBanBanGiaoMauPhongKeHoach}>
                {EXPORT_TEMPLATE.bienBanBanGiaoMauPhongKeHoach}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.phieuYeuCauKiemNghiem}>
                {EXPORT_TEMPLATE.phieuYeuCauKiemNghiem}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.phieuPhanTich}>
                {EXPORT_TEMPLATE.phieuPhanTich}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.phieuKiemNghiem}>
                {EXPORT_TEMPLATE.phieuKiemNghiem}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.soNhapMauLay}>
                {EXPORT_TEMPLATE.soNhapMauLay}
              </Select.Option>

              <Select.Option value={EXPORT_TEMPLATE.soNhapMauGui}>
                {EXPORT_TEMPLATE.soNhapMauGui}
              </Select.Option>
            </Select>
          </Col>
        </Row>
        <PdfReport
          type={typeReport}
          data={specialDataSource.length > 0 ? specialDataSource : dataSelected}
          sampleTemplate={form}
          code={Object.values(numberReports || {})[0]}
          dicReports={numberReports}
        ></PdfReport>
      </Modal>

      <Modal
        title={"Barcode"}
        visible={visiblePdfBarcode}
        centered
        width={"100%"}
        bodyStyle={{ height: "90vh" }}
        footer={null}
        onCancel={() => setVisiblePdfBarcode(false)}
      >
        <PDFViewer style={{ width: "100%", height: "100%" }}>
          <BarcodePdfView data={dataSelected} />
        </PDFViewer>
      </Modal>

      <SampleForm
        templateId={currentTemplate}
        configData={form}
        actionRef={modalRefSample}
        afterSubmit={handleAfterSubmit}
      />
      <SampleFormDerivative actionRef={modalRef} reset={() => reset()} />
      <FormCard actionRef={modalRefCard} reset={() => reset()} />
      <UploadTemplate
        isModalVisible={isOpenModalUpload}
        handleCancel={() => setIsOpenModalUpload(!isOpenModalUpload)}
        uploadFile={(file) => uploadFile(file)}
      />
      <ModalShowErr
        listErr={listErr}
        isError={isError}
        onCloseModalErr={closeModalError}
      />

      <ModalAnalyte actionRef={modalAnayle} reset={() => reset()} />

      {/* MODAL CLONE RECORD */}
      <CloneRecord itemRef={cloneRef} afterClone={handleAfterSubmit} />
      {/* MODAL CLONE RECORD */}
    </div>
  );
};

export default Samples;

export const EXPORT_TEMPLATE = {
  // samplingReport: "Biên bản lấy mẫu",
  // testRequest: "Phiếu yêu cầu phân tích",
  // receiveSampleReport: "Biên bản nhận mẫu",
  // sampleDelivery: "Phiếu giao mẫu",
  // sampleInformation: "Thông tin mẫu",
  // storageSampleInformation: "Thông tin mẫu lưu",
  // //BOOKS
  // sampleLogBook: "Sổ mẫu lưu",
  // sampleManagementBook: "Sổ quản lý mẫu kiểm nghiệm",

  //Biên bản
  bienBanLayMau: "Biên bản lấy mẫu",
  bienBanBanGiaoMau: "Biên bản bàn giao mẫu",
  bienBanBanGiaoMauPhongKeHoach: "Biên bản bàn giao mẫu phòng kế hoạch",

  //Sổ
  soNhapMauLay: "Sổ nhập mẫu lấy",
  soNhapMauGui: "Sổ nhập mẫu gửi",

  //Phiếu
  phieuPhanTich: "Phiếu phân tích",
  phieuKiemNghiem: "Phiếu kiểm nghiệm",
  phieuYeuCauKiemNghiem: "Phiếu yêu cầu kiểm nghiệm",
};
